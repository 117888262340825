const advisorState = {
  advisors: [],
  statistics: {
    assigned: 0,
    assigned_sold: 0,
    sold: 0,
    paid: 0,
  },
  myTickets: {
    assigned: [],
    sold: [],
    paid: [],
  },
};

export default advisorState;
