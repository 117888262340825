const ticketMutation = {
  setTickets(state, tickets) {
    state.tickets = tickets;
  },
  setTicketBook(state, ticketBook) {
    state.ticketBook = ticketBook;
    localStorage.setItem("ticket_book", JSON.stringify(ticketBook));
  },
  setTicketBooks(state, ticketBooks) {
    state.ticketBooks = ticketBooks;
    localStorage.setItem("ticket_books", JSON.stringify(ticketBooks));
  },
  setSearchedNumbers(state, searchedNumber) {
    state.searchedNumbers.push(searchedNumber);
  },
  setClearSearchedNumbers(state) {
    state.searchedNumbers = [];
  },
};

export default ticketMutation;
