import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import moment from "moment";
import { VueReCaptcha } from "vue-recaptcha-v3";

moment.locale("es");

const recaptchaOptions = {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
    explicitRenderParameters: {
      badge: "bottomleft",
      theme: "dark",
    },
  },
};

createApp(App)
  .use(store)
  .use(router)
  .use(VueReCaptcha, recaptchaOptions)
  .mount("#app");
