<template>
  <div>
    <h1>{{ isEdit ? "Editar" : "Crear" }} Perfil</h1>
    <form @submit.prevent="handledSubmit">
      <div class="mb-3">
        <label for="first_name" class="form-label">Nombres</label>
        <input
          type="text"
          class="form-control"
          id="first_name"
          v-model="profile_form.natural_person.first_name"
          required
        />
      </div>
      <div class="mb-3">
        <label for="last_name" class="form-label">Apellidos</label>
        <input
          type="text"
          class="form-control"
          id="last_name"
          v-model="profile_form.natural_person.last_name"
          required
        />
      </div>
      <div class="mb-3">
        <label for="phone_number" class="form-label">Número de teléfono</label>
        <input
          type="text"
          class="form-control"
          id="phone_number"
          v-model="profile_form.natural_person.contact_info.phone_number"
          required
        />
        <span v-if="phoneNumberError" class="text-danger"
          >Número de teléfono no válido.</span
        >
      </div>
      <div class="mb-3">
        <label for="address" class="form-label">Dirección</label>
        <input
          type="text"
          class="form-control"
          id="address"
          v-model="profile_form.natural_person.contact_info.address"
          required
        />
      </div>
      <div class="mb-3">
        <label for="neighborhood" class="form-label">Barrio</label>
        <input
          type="text"
          class="form-control"
          id="neighborhood"
          v-model="profile_form.natural_person.contact_info.neighborhood"
          required
        />
      </div>
      <div class="mb-3">
        <label for="city" class="form-label">Ciudad</label>
        <input
          type="text"
          class="form-control"
          id="city"
          v-model="profile_form.natural_person.contact_info.city"
          required
        />
      </div>
      <button type="submit" class="btn btn-primary">
        {{ isEdit ? "Actualizar" : "Crear" }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "FormProfile",
  data() {
    return {
      profile_form: {
        natural_person: {
          first_name: "",
          last_name: "",
          contact_info: {
            phone_number: "",
            address: "",
            neighborhood: "",
            city: "",
          },
        },
      },
      suid_ticket_book: "",
      phoneNumberError: false,
      isEdit: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      profile: (state) => state.profile,
    }),
  },
  created() {
    const { suid_ticket_book } = this.$route.params;
    if (suid_ticket_book) {
      this.suid_ticket_book = suid_ticket_book;
    }
    this.checkEditMode();
    if (this.isEdit) {
      this.loadProfileData();
    }
  },
  watch: {
    $route() {
      this.checkEditMode();
      if (this.isEdit) {
        this.loadProfileData();
      }
    },
  },
  methods: {
    ...mapActions(["createProfile", "updateProfile", "getProfile"]),
    async handledSubmit() {
      try {
        if (this.isEdit) {
          await this.handledUpdate();
        } else {
          await this.handledCreate();
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error al enviar el perfil!",
          footer: error.response.data.message,
        });
      }
    },
    async handledCreate() {
      try {
        const data = {
          ...this.profile_form,
          suid_ticket_book: this.suid_ticket_book,
        };
        await this.createProfile(data);
        if (this.suid_ticket_book) {
          this.$router.go(0);
        } else {
          this.$router.push("/profiles");
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error al crear el perfil!",
          footer: error.response.data.message,
        });
      }
    },
    async handledUpdate() {
      try {
        let profileData = { ...this.profile_form };
        if (this.profile.natural_person) {
          profileData.natural_person = {
            ...this.profile_form.natural_person,
            id: this.profile.natural_person.id,
            contact_info: {
              ...this.profile_form.natural_person.contact_info,
              id: this.profile.natural_person.contact_info.id,
            },
          };
        }
        let data = {
          profile: profileData,
          profile_id: this.user?.profile_id,
        };
        await this.updateProfile(data);
        this.$router.push("/profiles");
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error al actualizar el perfil!",
          footer: error.response.data.message,
        });
      }
    },
    checkEditMode() {
      if (this.$route.params.id) {
        this.isEdit = true;
        const profileId = parseInt(this.$route.params.id);
        if (profileId !== this.user?.profile_id) {
          this.$router.push("/");
        }
      } else {
        this.isEdit = false;
        if (this.user.profile_id && !this.suid_ticket_book) {
          this.$router.push("/");
        }
      }
    },
    async loadProfileData() {
      try {
        await this.getProfile(this.profile.id);
        this.profile_form.natural_person.first_name =
          this.profile.natural_person.first_name;
        this.profile_form.natural_person.last_name =
          this.profile.natural_person.last_name;
        this.profile_form.natural_person.contact_info.phone_number =
          this.profile.natural_person.contact_info.phone_number;
        this.profile_form.natural_person.contact_info.address =
          this.profile.natural_person.contact_info.address;
        this.profile_form.natural_person.contact_info.neighborhood =
          this.profile.natural_person.contact_info.neighborhood;
        this.profile_form.natural_person.contact_info.city =
          this.profile.natural_person.contact_info.city;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error al cargar los datos del perfil!",
          footer: error.response.data.message,
        });
      }
    },
  },
};
</script>
