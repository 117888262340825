<template>
  <div v-if="tickets.length">
    <h3 class="mt-0">Boletos</h3>
    <div
      class="row justify-content-center text-center mb-5 d-flex align-items-stretch"
    >
      <TicketItem
        v-for="ticket in tickets"
        :key="ticket.number"
        :ticket="ticket"
        @open-modal="openModal"
      />
    </div>
  </div>
</template>

<script>
import TicketItem from "./TicketItem.vue";

export default {
  name: "TicketList",
  components: {
    TicketItem,
  },
  props: {
    tickets: {
      type: Array,
      required: true,
    },
  },
  methods: {
    openModal(advancedPrizes) {
      this.$emit("open-modal", advancedPrizes);
    },
  },
};
</script>

<style scoped>
.card {
  background-color: #e4cb8b;
  color: #000000;
}
</style>
