<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 text-center mb-4">
        <img
          src="@/assets/logo.png"
          alt="Logo de la empresa"
          style="max-width: 200px; height: auto"
        />
      </div>
    </div>
    <h1>Regístrate</h1>
    <div class="row justify-content-center text-center">
      <div class="col-12 col-md-4 text-center">
        <form @submit.prevent="handledRegister">
          <div class="mb-3">
            <label for="username" class="form-label">Usuario</label>
            <input
              type="text"
              class="form-control"
              id="username"
              v-model="user.username"
              required
            />
            <div v-if="errors.username">
              <small
                v-for="(error, index) in errors.username"
                :key="index"
                class="text-danger"
                >{{ error }}</small
              >
            </div>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Correo Electrónico</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="user.email"
              required
            />
            <div v-if="errors.email">
              <small
                v-for="(error, index) in errors.email"
                :key="index"
                class="text-danger"
                >{{ error }}</small
              >
            </div>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Contraseña</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="user.password"
              required
            />
            <div v-if="errors.password">
              <small
                v-for="(error, index) in errors.password"
                :key="index"
                class="text-danger"
                >{{ error }}<br
              /></small>
            </div>
          </div>
          <button type="submit" class="btn btn-primary">Regístrate</button>
        </form>
      </div>
    </div>
    <p class="mt-3">
      ¿Tienes una cuenta?
      <router-link to="/login">Inicia Sesión</router-link>
    </p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "RegisterForm",
  data() {
    return {
      user: {
        username: "",
        email: "",
        password: "",
      },
      errors: {},
    };
  },
  methods: {
    ...mapActions(["register"]),
    async handledRegister() {
      try {
        await this.register(this.user);
        this.$router.push({ path: "/login", query: { registered: true } });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // Manejo de errores de validación del formulario
          this.errors = error.response.data;
          // Scroll hacia arriba para mostrar los errores debajo de los campos
          window.scrollTo(0, 0);
        } else {
          Swal.fire({
            icon: "error",
            title: "Registro fallido",
            text: error.message || "Error desconocido",
          });
        }
      }
    },
  },
};
</script>
