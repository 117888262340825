<template>
  <div class="profile-detail">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h2>Perfil</h2>
      <!-- <router-link
        class="btn btn-secondary"
        :to="`/profiles/edit/${profile?.id}`"
        >Edit</router-link
      > -->
    </div>

    <div v-if="profile" class="row row-cols-1 row-cols-md-3 g-4">
      <div class="col" v-if="profile.natural_person">
        <div class="card">
          <div class="card-header">Información Personal</div>
          <div class="card-body">
            <p>
              <strong>Nombres:</strong>
              {{ profile.natural_person.first_name }}
            </p>
            <p>
              <strong>Apellidos:</strong> {{ profile.natural_person.last_name }}
            </p>
            <p><strong>Usuario:</strong> {{ user?.username }}</p>
          </div>
        </div>
      </div>

      <div class="col" v-if="profile.natural_person.contact_info">
        <div class="card">
          <div class="card-header">Información de Contacto</div>
          <div class="card-body">
            <p>
              <strong>Correo electronico:</strong>
              {{ profile.natural_person.contact_info.email }}
            </p>
            <p>
              <strong>Número de teléfono:</strong>
              {{ profile.natural_person.contact_info.phone_number }}
            </p>
            <p>
              <strong>Dirección:</strong>
              {{ profile.natural_person.contact_info.address }}
            </p>
            <p>
              <strong>Barrio:</strong>
              {{ profile.natural_person.contact_info.neighborhood }}
            </p>
            <p>
              <strong>Ciudad:</strong>
              {{ profile.natural_person.contact_info.city }}
            </p>
          </div>
        </div>
      </div>

      <div class="col" v-if="profile.legal_entity">
        <div class="card">
          <div class="card-header">Legal Entity</div>
          <div class="card-body">
            <p><strong>Entity Name:</strong> {{ profile.legal_entity.name }}</p>
            <p>
              <strong>Registration Number:</strong>
              {{ profile.legal_entity.registration_number }}
            </p>
          </div>
        </div>
      </div>

      <div class="col" v-if="profile.identity_document">
        <div class="card">
          <div class="card-header">Identity Document</div>
          <div class="card-body">
            <p>
              <strong>Document Type:</strong>
              {{ profile.identity_document.type }}
            </p>
            <p>
              <strong>Document Number:</strong>
              {{ profile.identity_document.number }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <p>Cargando...</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ProfileDetail",
  computed: {
    ...mapState(["user", "profile"]),
  },
  async created() {
    try {
      if (this.user && this.user.profile_id) {
        await this.getProfile(this.user.profile_id);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error al obtener los detalles del perfil!",
        footer: error.response.data.message,
      });
    }
  },
  methods: {
    ...mapActions(["getProfile"]),
  },
};
</script>
