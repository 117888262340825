<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Consulta tus Boletos</h5>
      <form @submit.prevent="fetchTickets">
        <div class="mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Teléfono o WhatsApp"
            id="phone"
            v-model="phone"
            pattern="[0-9]{10}"
            title="Ingrese exactamente 10 dígitos"
            maxlength="10"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')"
            required
            inputmode="numeric"
          />
        </div>
        <button type="submit" class="btn btn-primary">Consultar</button>
      </form>
      <span
        v-if="fetchingTickets"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "TicketForm",
  data() {
    return {
      phone: "",
      fetchingTickets: false,
    };
  },
  computed: {
    ...mapState(["tickets"]),
  },
  methods: {
    ...mapActions(["getTicketsByPhone"]),
    async fetchTickets() {
      this.fetchingTickets = true;
      try {
        await this.getTicketsByPhone(this.phone);
        if (this.tickets.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Aun no has obtenido ningun ticket!",
            footer: "Comunicate con tu asesor de confianza",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error al cargar los datos de los boletos!",
          footer: error.response.data.message,
        });
      } finally {
        this.fetchingTickets = false;
      }
    },
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: #50bfe0;
  color: #ffffff;
}
.card {
  background-color: #e4cb8b;
  color: #000000;
}
</style>
