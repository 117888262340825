<template>
  <div class="container px-4 py-5" id="featured-3">
    <h2 class="pb-2 border-bottom">Asesores</h2>
    <button class="btn btn-primary" @click="activateForm">Crear</button>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6">
        <h2 class="pb-2 border-bottom">Asignar Tickets</h2>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-4">
            <strong class="d-inline-block mb-2 text-primary-emphasis"
              >Teléfono</strong
            >
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                v-model="phoneNumber"
                id="floatingPhoneNumber"
                placeholder="Teléfono"
                @input="handleFilterAdvisors"
                oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                inputmode="numeric"
              />
              <label for="floatingPassword">Telefono</label>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-4">
            <div class="row">
              <strong class="d-inline-block mb-2 text-primary-emphasis"
                >Rango</strong
              >
              <div class="col-6">
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    class="form-control"
                    v-model="startRange"
                    id="floatingStartRange"
                    placeholder="Inicia"
                    :pattern="'[0-9]{' + ticketBook.digits_quantity + '}'"
                    :maxlength="ticketBook.digits_quantity"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                    inputmode="numeric"
                  />
                  <label for="floatingInput">Inicia</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    v-model="endRange"
                    id="floatingEndRange"
                    placeholder="Termina"
                    :pattern="'[0-9]{' + ticketBook.digits_quantity + '}'"
                    :maxlength="ticketBook.digits_quantity"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                    inputmode="numeric"
                  />
                  <label for="floatingPassword">Termina</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-4">
            <strong class="d-inline-block mb-2 text-primary-emphasis"
              >Unidades</strong
            >
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                v-model="units"
                id="floatingNumbers"
                placeholder="Numeros"
              />
              <label for="floatingPassword">Numeros</label>
            </div>
          </div>
          <div v-if="errorMessage" class="text-danger mt-2">
            {{ errorMessage }}
          </div>
        </div>
        <div v-if="unassigned_numbers.length > 0" class="text-danger mt-2">
          Estos numeros no se pudieron asignar
          <span v-for="number in unassigned_numbers" :key="number">
            {{ number }},
          </span>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
                <th scope="col">Apellido</th>
                <th scope="col">Teléfono</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(advisor, index) in advisors" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ advisor.first_name }}</td>
                <td>{{ advisor.last_name }}</td>
                <td>{{ advisor.phone_number }}</td>
                <td>
                  <button
                    @click="handledAssignTickets(advisor.suid)"
                    type="button"
                    class="btn btn-primary"
                    :disabled="!isButtonEnabled"
                  >
                    Asignar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <FormProfile v-if="create"></FormProfile>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import FormProfile from "../components/profiles/Form.vue";

export default {
  name: "AdvisorManagement",
  components: {
    FormProfile,
  },
  data() {
    return {
      suid_ticket_book: "",
      phoneNumber: "",
      startRange: "",
      endRange: "",
      units: "",
      activateAssign: false,
      errorMessage: "",
      create: false,
      unassigned_numbers: [],
    };
  },
  async created() {
    const { suid_ticket_book } = this.$route.params;
    this.suid_ticket_book = suid_ticket_book;
    try {
      await this.getTicketBookInfo(this.suid_ticket_book);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error obteniendo informacion del talonario!",
        footer: error.response.data.message,
      }).then(() => {
        this.$router.push("/");
      });
    }
    if (this.ticketBook.owner !== this.profile.suid) {
      this.$router.push("/");
    }
    try {
      await this.listAdvisors(this.suid_ticket_book);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error obteniendo informacion de asesores!",
        footer: error.response.data.message,
      });
    }
  },
  methods: {
    ...mapActions([
      "getTicketBookInfo",
      "listAdvisors",
      "filterAdvisors",
      "filterAdvisor",
      "assignTickets",
    ]),
    async handleFilterAdvisors(event) {
      if (this.validatePhoneNumber(event.target.value)) {
        const data = {
          suid_ticket_book: this.suid_ticket_book,
          phone_number: event.target.value,
        };
        await this.filterAdvisors(data);
      }
    },
    async handledAssignTickets(suid_advisor) {
      this.errorMessage = "";
      if (this.startRange || this.endRange) {
        this.validateRange();
      }
      if (this.units) {
        this.validateUnits();
      }
      if (!this.errorMessage) {
        const data = {
          suid_ticket_book: this.suid_ticket_book,
          suid_advisor: suid_advisor,
          start_range: this.startRange,
          end_range: this.endRange,
          units: this.units,
        };
        try {
          const response = await this.assignTickets(data);
          this.unassigned_numbers = response.data.unassigned_numbers;
          Swal.fire({
            icon: "success",
            title: "Exito!",
            text: "Se asignaron los boletos correactamente!",
            footer: response.message,
          }).then(() => {
            this.startRange = "";
            this.endRange = "";
            this.units = "";
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error asignado los boletos!",
            footer: error.response.data.message,
          });
        }
      }
    },
    validatePhoneNumber(phoneNumber) {
      return phoneNumber >= 10;
    },
    validateRange() {
      if (this.startRange && !this.endRange) {
        this.errorMessage = `Se requiere rango final`;
      } else if (!this.startRange && this.endRange) {
        this.errorMessage = `Se requiere rango inicial`;
      } else if (this.startRange > this.endRange) {
        this.errorMessage = `El rango inicial no puede ser mayor al final`;
      }

      if (this.units) {
        const start = parseInt(this.startRange, 10);
        const end = parseInt(this.endRange, 10);
        const unitsArray = this.units
          .split(",")
          .map((unit) => parseInt(unit.trim(), 10));
        const invalidUnits = unitsArray.filter(
          (unit) => unit >= start && unit <= end
        );

        if (invalidUnits.length > 0) {
          this.errorMessage = `Las siguientes unidades ya hacen parte del rango: ${invalidUnits.join(
            ", "
          )}`;
        }
      }
    },
    validateUnits() {
      const quantity = this.ticketBook.digits_quantity;
      const unitsArray = this.units.split(",").map((unit) => unit.trim());
      const invalidUnits = unitsArray.filter((unit) => unit.length > quantity);
      if (invalidUnits.length > 0) {
        this.errorMessage = `Las siguientes unidades no cumplen con el rango del sorteo: ${invalidUnits.join(
          ", "
        )}`;
      }
    },
    activateForm() {
      this.create = true;
    },
  },
  computed: {
    ...mapState(["profile", "ticketBook", "advisors"]),
    isButtonEnabled() {
      // Comprueba si startRange, endRange, o units tienen datos
      return (
        this.validatePhoneNumber(this.phoneNumber) &&
        ((this.startRange.trim() !== "" && this.endRange.trim() !== "") ||
          this.units.trim() !== "")
      );
    },
  },
};
</script>

<style scoped></style>
