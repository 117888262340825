<template>
  <div v-if="loading" class="col-lg-3 d-flex grid-margin stretch-card">
    <div class="card skeleton-card">
      <div class="card-body">
        <h4
          v-for="line in lines"
          :key="line"
          class="card-title mb-2 skeleton-text"
        ></h4>
      </div>
    </div>
  </div>
  <div v-else class="col-lg-3 d-flex grid-margin stretch-card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SkeletonLoader",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    lines: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Skeleton styles */
.skeleton-card {
  background-color: #ffffff;
  border-radius: 4px;
}

.skeleton-text {
  background-color: #d0d0d0;
  height: 20px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
