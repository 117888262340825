// src/axios.js
import axios from "axios";
import store from "./store";
import AuthService from "./services/AuthService";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_PUBLIC_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = store.state.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && !originalRequest._retry) {
      if (error.response.status === 401 || error.response.status === 403) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem("refresh_token");
        if (refreshToken) {
          try {
            const response = await AuthService.refreshToken(refreshToken);
            store.commit("setAccessToken", response.data.access);
            originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
            return apiClient(originalRequest);
          } catch (refreshError) {
            store.commit("logout");
            return Promise.reject(refreshError);
          }
        }
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;
