const authMutation = {
  setAccessToken(state, token) {
    state.accessToken = token;
    localStorage.setItem("access_token", token);
  },
  setRefreshToken(state, token) {
    state.refreshToken = token;
    localStorage.setItem("refresh_token", token);
  },
  logout(state) {
    state.accessToken = "";
    state.refreshToken = "";
    state.user = null;
    state.profile = null;
    state.tickets = [];
    state.ticketBook = null;
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    localStorage.removeItem("profile");
  },
};

export default authMutation;
