const userMutation = {
  setUser(state, user) {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
  setProfile(state, profile) {
    state.profile = profile;
    state.user.profile_id = profile.id;
    state.user.first_name = profile.natural_person.first_name;
    localStorage.setItem("user", JSON.stringify(state.user));
    localStorage.setItem("profile", JSON.stringify(profile));
  },
};

export default userMutation;
