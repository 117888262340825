// src/services/AdvisorService.js
import apiClient from "../axios";

export default {
  listAdvisors(suid_ticket_book) {
    return apiClient.get(`/advisors/?suid_ticket_book=${suid_ticket_book}`);
  },
  filterAdvisors(suid_ticket_book, phone_number) {
    return apiClient.get(
      `/advisors/?suid_ticket_book=${suid_ticket_book}&&phone_number=${phone_number}`
    );
  },
  referenceAdvisor(data) {
    return apiClient.post(`/advisors/reference/`, data);
  },
  assignTickets(data) {
    return apiClient.post(`/advisors/assign_tickets/`, data);
  },
  statistics() {
    return apiClient.get(`/advisors/statistics/`);
  },
  myTickets() {
    return apiClient.get(`/advisors/my_tickets/`);
  },
};
