<template>
  <div class="col-12 col-md-6 mb-4">
    <div class="card mt-3 h-100 align-items-center">
      <div class="card-body">
        <h5 class="card-title">Premio Principal</h5>
        <h4 class="card-text">{{ ticket.main_prize_description }}</h4>
        <p class="card-text">
          Número del Boleto:
          <span class="badge text-bg-success badge-lg">{{
            ticket.number
          }}</span>
        </p>
        <div class="row">
          <div class="col-12 col-md-6">
            <p class="mb-1">Fecha del Sorteo:</p>
            <p class="mt-1">{{ formattedDrawDate(ticket.draw_date) }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p class="mb-1">Juega con:</p>
            <p class="mt-1">{{ ticket.game_name }}</p>
          </div>
        </div>
        <p class="card-text">
          Valor del Boleto: {{ formatCurrency(ticket.ticket_value) }}
        </p>
        <p class="card-text">Estado: {{ ticket.state }}</p>
        <p class="card-text">
          Valor Pagado: {{ formatCurrency(ticket.paid_amount) }}
        </p>
        <button
          class="btn btn-secondary"
          @click="openModal(ticket.advanced_prizes)"
          data-bs-toggle="modal"
          data-bs-target="#advancedPrizesModal"
        >
          Ver Premios Anticipados ({{ ticket.advanced_prizes.length }})
        </button>
        <!-- <router-link
          class="btn btn-primary mt-3"
          :to="`/ticket/obtain/${ticket.suid}/`"
          >Obtener más boletos</router-link
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TicketItem",
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatCurrency(value) {
      const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formattedDrawDate(value) {
      return this.capitalize(moment(value).format("dddd D [de] MMMM YYYY"));
    },
    openModal(advancedPrizes) {
      this.$emit("open-modal", advancedPrizes);
    },
  },
};
</script>

<style scoped>
.card {
  background-color: #e4cb8b;
  color: #000000;
}
.btn-primary {
  background-color: #50bfe0;
  color: #ffffff;
}
.badge-lg {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
</style>
