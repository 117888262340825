import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";

const authAction = {
  async register(context, user) {
    await AuthService.register(user);
  },
  async login({ commit }, credentials) {
    const response = await AuthService.login(credentials);
    commit("setAccessToken", response.data.access);
    commit("setRefreshToken", response.data.refresh);
    const userResponse = await AuthService.getUser();
    commit("setUser", userResponse.data);
    if (userResponse.data.profile_id) {
      const profileResponse = await UserService.getProfile(
        userResponse.data.profile_id
      );
      commit("setProfile", profileResponse.data);
    }
  },
  async refreshToken({ commit, state }) {
    const response = await AuthService.refreshToken(state.refreshToken);
    commit("setAccessToken", response.data.access);
  },
  logout({ commit }) {
    commit("logout");
  },
};

export default authAction;
