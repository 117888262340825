import authMutation from "./authMutations";
import userMutation from "./userMutations";
import ticketMutation from "./ticketMutations";
import advisorMutation from "./advisorMutations";

const mutations = {
  ...authMutation,
  ...userMutation,
  ...ticketMutation,
  ...advisorMutation,
};

export default mutations;
