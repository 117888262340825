<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 col-md-4 mx-auto mb-5">
        <div class="row">
          <div class="col-12 text-center mb-4">
            <img
              src="@/assets/logo.png"
              alt="Logo de la empresa"
              style="max-width: 200px; height: auto"
            />
          </div>
          <div class="col-12 mx-auto">
            <TicketForm @fetch-tickets="fetchTickets" />
          </div>
        </div>
      </div>
      <div v-if="tickets.length" class="col-12 col-md-8 mx-auto">
        <TicketList :tickets="tickets" @open-modal="openModal" />
      </div>
    </div>
    <AdvancedPrizesModal :currentAdvancedPrizes="currentAdvancedPrizes" />
  </div>
</template>

<script>
import TicketForm from "../components/tickets/retrieve/TicketForm.vue";
import TicketList from "../components/tickets/retrieve/TicketList.vue";
import AdvancedPrizesModal from "../components/tickets/retrieve/AdvancedPrizesModal.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "RetrieveTickets",
  components: {
    TicketForm,
    TicketList,
    AdvancedPrizesModal,
  },
  data() {
    return {
      currentAdvancedPrizes: [],
    };
  },
  computed: {
    ...mapState(["tickets"]),
  },
  methods: {
    ...mapActions(["getTicketsByPhone"]),
    async fetchTickets(phone) {
      try {
        await this.getTicketsByPhone(phone);
      } catch (error) {
        // Handle error
      }
    },
    openModal(advancedPrizes) {
      this.currentAdvancedPrizes = advancedPrizes;
    },
  },
};
</script>

<style scoped>
body {
  background-color: #f8f9fa !important; /* Color de fondo neutro */
}
</style>
