<template>
  <div>
    <h1>Activación de la cuenta...</h1>
    <p v-if="message">{{ message }}</p>
    <p v-else>Activando su cuenta, por favor espere...</p>
  </div>
</template>

<script>
import axiosInstance from "../axios";

export default {
  name: "ActivateUser",
  data() {
    return {
      message: "",
    };
  },
  async created() {
    const { uid, token } = this.$route.params;
    try {
      // eslint-disable-next-line
      const response = await axiosInstance.post("/auth/users/activation/", {
        uid,
        token,
      });
      this.message = "Su cuenta ha sido activada con éxito!";
      this.$router.push({ path: "/login", query: { activated: true } });
    } catch (error) {
      this.message =
        "Error en la activación de la cuenta. Vuelva a intentarlo más tarde.";
    }
  },
};
</script>
