<template>
  <div>
    <header class="mb-auto">
      <nav class="navbar navbar-expand-lg navbar-dark bg-black">
        <div class="container">
          <router-link class="navbar-brand text-gold" to="/"
            >Vio! La suerte</router-link
          >
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item" v-if="user">
                <router-link class="nav-link text-white" to="/dashboard"
                  >Dashboard</router-link
                >
              </li>
              <li class="nav-item" v-if="user">
                <router-link class="nav-link text-white" to="/ticket_books"
                  >Talonarios</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link text-white" to="/ticket/retrieve"
                  >Consultar</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link text-white" to="/about"
                  >Nosotros</router-link
                >
              </li>
              <li class="nav-item dropdown" v-if="user">
                <a
                  class="nav-link dropdown-toggle btn btn-link d-flex align-items-center text-white"
                  id="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    width="30"
                    height="30"
                    ref="userAvatar"
                    class="me-2"
                  ></svg>
                  <span>
                    {{
                      user?.first_name
                        ? `Bienvenido, ${user.first_name}`
                        : "Bienvenido"
                    }}
                  </span>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <svg
                      width="80"
                      height="80"
                      ref="userDropdownAvatar"
                      class="dropdown-item"
                    ></svg>
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <router-link class="dropdown-item text-black" to="/profiles"
                      >Perfil</router-link
                    >
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <button
                      class="dropdown-item text-black"
                      @click="handleLogout"
                    >
                      Cerrar Sesión
                    </button>
                  </li>
                </ul>
              </li>
              <li class="nav-item" v-if="!user">
                <router-link class="nav-link text-white" to="/login"
                  >Iniciar Sesión</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <main class="px-3">
      <div class="container mt-4">
        <router-view></router-view>
      </div>
    </main>
    <footer class="footer mt-auto py-3">
      <div class="container-fluid">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top"
        >
          <p class="col-md-4 mb-0 text-body-secondary">© 2024 CValenciaIT</p>

          <a
            href="/"
            class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
          >
            <img
              src="@/assets/logo.png"
              class="d-block mx-lg-auto img-fluid"
              alt="Logo Vio! La suerte"
              width="40"
              height="32"
              loading="lazy"
            />
          </a>

          <ul class="nav col-md-4 justify-content-end">
            <!-- <li class="nav-item">
              <a href="#" class="nav-link px-2 text-body-secondary">Home</a>
            </li> -->
            <li class="nav-item">
              <router-link
                to="/ticket/retrieve"
                class="nav-link px-2 text-body-secondary"
                >Consultar</router-link
              >
            </li>
            <!-- <li class="nav-item">
              <a href="#" class="nav-link px-2 text-body-secondary">Pricing</a>
            </li> -->
            <!-- <li class="nav-item">
              <a href="#" class="nav-link px-2 text-body-secondary">FAQs</a>
            </li> -->
            <li class="nav-item">
              <router-link to="/about" class="nav-link px-2 text-body-secondary"
                >Nosotros</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "LayoutComponent",
  computed: {
    ...mapState({
      user: (state) => state.user,
      profile: (state) => state.profile,
    }),
  },
  watch: {
    user: {
      immediate: true,
      // eslint-disable-next-line
      handler(newUser) {
        this.updateAvatar();
      },
    },
  },
  mounted() {
    this.updateAvatar();
  },
  methods: {
    ...mapActions(["logout"]),
    async handleLogout() {
      try {
        await this.logout();
        this.$router.push("/login");
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Algo salió mal con el cierre de sesión!",
          footer: error.response.data.message,
        });
      }
    },
    updateAvatar() {
      try {
        const userName = this.user?.first_name || "default";
        // eslint-disable-next-line
        jdenticon.update(this.$refs.userAvatar, userName);
        // eslint-disable-next-line
        jdenticon.update(this.$refs.userDropdownAvatar, userName);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error al actualizar el avatar!",
          footer: error.response.data.message,
        });
      }
    },
  },
};
</script>

<style scoped>
.nav-link {
  color: #2c3e50;
  font-weight: bold;
}

.nav-link:hover {
  color: #42b983;
}

.nav-link.router-link-exact-active {
  color: #42b983;
  font-weight: bold;
}

.bg-black {
  background-color: #000000;
}

.text-gold {
  color: #e4cb8b;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}
</style>
