// src/services/TicketService.js
import apiClient from "../axios";

export default {
  getTicketBooks() {
    return apiClient.get(`/ticket_books/`);
  },
  getTicketsByPhone(phone_or_whatsapp) {
    return apiClient.get(
      `/ticket_books/get_tickets_by_phone/?phone_or_whatsapp=${phone_or_whatsapp}`
    );
  },
  getTicketBookInfo(suid) {
    return apiClient.get(`/ticket_books/get_info/?suid=${suid}`);
  },
  searchNumberTicket(suid_ticket_book, suid_advisor, number) {
    return apiClient.get(
      `/ticket_books/search_ticket/?suid_ticket_book=${suid_ticket_book}&&suid_advisor=${suid_advisor}&&is_random=false&&number=${number}`
    );
  },
  searchRandomTicket(suid_ticket_book) {
    return apiClient.get(
      `/ticket_books/search_ticket/?suid_ticket_book=${suid_ticket_book}&&is_random=true`
    );
  },
  enableTickets(suid_ticket_book, suid_advisor, numbers) {
    return apiClient.patch(
      `/ticket_books/enable_tickets/${suid_ticket_book}/${suid_advisor}/`,
      numbers
    );
  },
  obtainTickets(data) {
    return apiClient.post(`/ticket_books/obtain_tickets/`, data);
  },
};
