import { createRouter, createWebHistory } from "vue-router";
import Register from "../views/Register.vue";
import Login from "../views/Login.vue";
import Logout from "../components/Logout.vue";
import Layout from "../components/Layout.vue";
import Dashboard from "../components/Dashboard.vue";
import FormProfile from "../components/profiles/Form.vue";
import ProfileDetail from "../components/profiles/Detail.vue";
import TicketBooks from "../views/TicketBooks.vue";
import RetrieveTickets from "../views/RetrieveTickets.vue";
import ObtainTicket from "@/views/ObtainTickets.vue";
import Activate from "../views/Activate.vue";
import AdvisorManagement from "../views/AdvisorManagement.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    component: Layout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        redirect: "/ticket/retrieve",
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/profiles",
        component: ProfileDetail,
        name: "ProfileDetail",
      },
      {
        path: "/profiles/create/:suid_ticket_book?",
        name: "CreateProfile",
        component: FormProfile,
      },
      // {
      //   path: "profiles/edit/:id",
      //   name: "ProfileEdit",
      //   component: FormProfile,
      // },
      {
        path: "/ticket_books",
        component: TicketBooks,
      },
      {
        path: "/ticket/obtain/:suid_ticket_book/:suid_advisor",
        component: ObtainTicket,
      },
      {
        path: "/advisor/management/:suid_ticket_book",
        component: AdvisorManagement,
      },
      {
        path: "/reference/advisor/ticket_book/:suid_ticket_book/:suid_reference_advisor",
        component: AdvisorManagement,
      },
    ],
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },
      {
        path: "",
        redirect: "/ticket/retrieve",
      },
      {
        path: "/boleta/consulta",
        redirect: "/ticket/retrieve",
      },
      {
        path: "/ticket/retrieve",
        component: RetrieveTickets,
      },
    ],
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/activate/:uid/:token",
    name: "Activate",
    component: Activate,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { title } = to.meta;
  const defaultTitle = "Vio! La suerte";
  document.title = title || defaultTitle;

  const token = localStorage.getItem("access_token");
  const userProfileId = store.state.user?.profile_id;
  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    next({ name: "Login" });
  } else if (to.name !== "CreateProfile" && userProfileId === null) {
    next({ name: "CreateProfile" });
  } else {
    next();
  }
});

export default router;
