import UserService from "@/services/UserService";

const userAction = {
  async getProfile({ commit }, id) {
    const response = await UserService.getProfile(id);
    commit("setProfile", response.data);
  },
  async createProfile({ commit }, profile) {
    const response = await UserService.createProfile(profile);
    if (!profile.suid_ticket_book) {
      commit("setProfile", response.data);
    }
  },
  async updateProfile({ commit }, data) {
    const id = data.profile_id;
    const profile = data.profile;
    const response = await UserService.updateProfile(id, profile);
    commit("setProfile", response.data);
  },
};

export default userAction;
