import authAction from "./authAction";
import userAction from "./userAction";
import ticketAction from "./ticketAction";
import advisorAction from "./advisorAction";

const actions = {
  ...authAction,
  ...userAction,
  ...ticketAction,
  ...advisorAction,
};

export default actions;
