const advisorMutation = {
  setAdvisors(state, advisors) {
    state.advisors = advisors;
    localStorage.setItem("advisors", JSON.stringify(advisors));
  },
  setStatistics(state, statistics) {
    state.statistics = statistics;
  },
  setMyTickets(state, myTickets) {
    state.myTickets = myTickets;
  },
};

export default advisorMutation;
