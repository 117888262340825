<template>
  <div>
    <h1>Logging out...</h1>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LogoutComponent",
  created() {
    this.logout();
  },
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>
