import TicketService from "@/services/TicketService";

const ticketAction = {
  async getTicketBooks({ commit }) {
    const response = await TicketService.getTicketBooks();
    commit("setTicketBooks", response.data);
  },
  async getTicketsByPhone({ commit }, phone_or_whatsapp) {
    const response = await TicketService.getTicketsByPhone(phone_or_whatsapp);
    commit("setTickets", response.data);
  },
  async getTicketBookInfo({ commit }, suid) {
    const response = await TicketService.getTicketBookInfo(suid);
    commit("setTicketBook", response.data);
  },
  async searchNumberTicket({ commit }, data) {
    const response = await TicketService.searchNumberTicket(
      data.suid_ticket_book,
      data.suid_advisor,
      data.ticket_number
    );
    commit("setSearchedNumbers", response.data);
  },
  async searchRandomTicket({ commit }, data) {
    const response = await TicketService.searchRandomTicket(
      data.suid_ticket_book
    );
    commit("setSearchedNumbers", response.data);
  },
  async clearSearchedNumbers({ commit }, data) {
    await TicketService.enableTickets(
      data.suid_ticket_book,
      data.suid_advisor,
      data.numbers
    );
    commit("setClearSearchedNumbers");
  },
  async obtainTickets({ commit }, data) {
    await TicketService.obtainTickets(data);
    commit("setClearSearchedNumbers");
  },
};

export default ticketAction;
