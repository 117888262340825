import authState from "./authState";
import userState from "./userState";
import ticketState from "./ticketState";
import advisorState from "./advisorState";

const state = {
  ...authState,
  ...userState,
  ...ticketState,
  ...advisorState,
};

export default state;
