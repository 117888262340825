// src/services/AuthService.js
import apiClient from "../axios";

export default {
  login(credentials) {
    return apiClient.post("/auth/jwt/create/", credentials);
  },
  register(user) {
    return apiClient.post("/auth/users/", user);
  },
  refreshToken(refresh) {
    return apiClient.post("/auth/jwt/refresh/", { refresh });
  },
  getUser() {
    return apiClient.get("/users/me/");
  },
  getRegisteredUser(username) {
    return apiClient.get(`/users/${username}/registered/`);
  },
};
