// src/services/ProfileService.js
import apiClient from "../axios";

export default {
  getProfile(id) {
    return apiClient.get(`/profiles/${id}/`);
  },
  createProfile(profile) {
    return apiClient.post("/profiles/", profile);
  },
  updateProfile(id, profile) {
    return apiClient.patch(`/profiles/${id}/`, profile);
  },
};
