<template>
  <div class="content-wrapper">
    <div class="row mt-4">
      <div class="row">
        <SkeletonLoader :loading="loading" :lines="4">
          <div class="card sale-diffrence-border">
            <div class="card-body">
              <h2 class="text-dark mb-2 font-weight-bold">
                {{ formatCurrency(this.myTickets.ticket_value) }}
              </h2>
              <h4 class="card-title mb-2">Valor del Boleto</h4>
              <small class="text-muted">C/U</small>
            </div>
          </div>
        </SkeletonLoader>
        <SkeletonLoader :loading="loading" :lines="4">
          <div class="card sale-diffrence-border">
            <div class="card-body">
              <h2 class="text-dark mb-2 font-weight-bold">
                {{ this.myTickets.advisory_commission }}%
              </h2>
              <h4 class="card-title mb-2">Comisión</h4>
              <small class="text-muted">PORCENTAJE</small>
            </div>
          </div>
        </SkeletonLoader>
        <SkeletonLoader :loading="loading" :lines="4">
          <div class="card sale-info-statistics-border">
            <div class="card-body">
              <h2 class="text-dark mb-2 font-weight-bold">
                {{
                  formatCurrency(
                    ((this.myTickets.ticket_value *
                      this.myTickets.advisory_commission) /
                      100) *
                      this.statistics.sold
                  )
                }}
              </h2>
              <h4 class="card-title mb-2">Comisión</h4>
              <small class="text-muted">VENDIDOS</small>
            </div>
          </div>
        </SkeletonLoader>
        <SkeletonLoader :loading="loading" :lines="4">
          <div class="card sale-visit-statistics-border">
            <div class="card-body">
              <h2 class="text-dark mb-2 font-weight-bold">
                {{
                  formatCurrency(
                    ((this.myTickets.ticket_value *
                      this.myTickets.advisory_commission) /
                      100) *
                      this.statistics.paid
                  )
                }}
              </h2>
              <h4 class="card-title mb-2">Comisión</h4>
              <small class="text-muted">PAGADOS</small>
            </div>
          </div>
        </SkeletonLoader>
      </div>
      <div class="col-12">
        <div class="row">
          <div
            class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card"
          >
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Boletos Asignados</h4>
                <div class="d-flex justify-content-between">
                  <p class="text-muted">Total:</p>
                  <p class="text-muted">
                    {{
                      this.statistics.assigned +
                      this.statistics.assigned_sold +
                      this.statistics.assigned_paid
                    }}
                  </p>
                </div>
                <Doughnut :data="chartDataAssigned" :options="chartOptions" />
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card"
          >
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Boletos Vendidos</h4>
                <div class="d-flex justify-content-between">
                  <p class="text-muted">Total:</p>
                  <p class="text-muted">
                    {{ this.statistics.sold + this.statistics.paid }}
                  </p>
                </div>
                <Doughnut :data="chartDataSold" :options="chartOptions" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion mt-4" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          Vendidos
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse show"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <div class="table-responsive mt-4">
            <table class="table table-hover align-middle">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Numero</th>
                  <th scope="col">Nombres</th>
                  <th scope="col">Apellidos</th>
                  <th scope="col">Monto pagado</th>
                  <th scope="col">Celular</th>
                  <th scope="col">Dirección</th>
                  <th scope="col">Ciudad</th>
                  <th scope="col">Observación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ticket, index) in myTickets.sold" :key="index">
                  <th scope="row">
                    {{ index + 1 }}
                  </th>
                  <td>{{ ticket?.number }}</td>
                  <td>{{ ticket?.first_name }}</td>
                  <td>{{ ticket?.last_name }}</td>
                  <td>{{ formatCurrency(ticket?.paid_amount) }}</td>
                  <td>{{ ticket?.phone }}</td>
                  <td>{{ ticket?.address }}</td>
                  <td>{{ ticket?.city }}</td>
                  <td>{{ ticket?.obs }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          Pagados
        </button>
      </h2>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <div class="table-responsive mt-4">
            <table class="table table-hover align-middle">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Numero</th>
                  <th scope="col">Nombres</th>
                  <th scope="col">Apellidos</th>
                  <th scope="col">Monto pagado</th>
                  <th scope="col">Celular</th>
                  <th scope="col">Dirección</th>
                  <th scope="col">Ciudad</th>
                  <th scope="col">Observación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ticket, index) in myTickets.paid" :key="index">
                  <th scope="row">
                    {{ index + 1 }}
                  </th>
                  <td>{{ ticket?.number }}</td>
                  <td>{{ ticket?.first_name }}</td>
                  <td>{{ ticket?.last_name }}</td>
                  <td>{{ formatCurrency(ticket?.paid_amount) }}</td>
                  <td>{{ ticket?.phone }}</td>
                  <td>{{ ticket?.address }}</td>
                  <td>{{ ticket?.city }}</td>
                  <td>{{ ticket?.obs }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
        >
          Asignados
        </button>
      </h2>
      <div
        id="collapseThree"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <div class="row">
            <div
              v-for="(ticket, index) in myTickets.assigned"
              :key="index"
              class="col-4 col-md-2 mx-auto mb-3"
            >
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{ ticket.number }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Doughnut } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import SkeletonLoader from "./SkeletonLoader.vue";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

export default {
  name: "DashboardComponent",
  components: {
    Doughnut,
    SkeletonLoader,
  },
  data() {
    return {
      loading: true,
      chartDataAssigned: {
        labels: ["Asignados", "Vendidos", "Pagados"],
        datasets: [
          {
            label: "Boletos asignados",
            data: [0, 0, 0], // Inicialmente vacío
            backgroundColor: ["#8083eb", "#83dae7", "#6ed4c3"],
            borderColor: ["#464dee", "#0ad7f7", "#0ddbb9"],
            borderWidth: 1,
          },
        ],
      },
      chartDataSold: {
        labels: ["Asignados", "Vendidos"],
        datasets: [
          {
            label: "Boletos Vendidos",
            data: [0, 0], // Inicialmente vacío
            backgroundColor: ["#83dae7", "#6ed4c3"],
            borderColor: ["#0ad7f7", "#0ddbb9"],
            borderWidth: 1,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.label}: ${tooltipItem.raw}`;
              },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState(["user", "statistics", "myTickets"]),
  },
  methods: {
    ...mapActions(["getMyTickets", "getStatistics"]),
    async fetchData() {
      this.loading = true;
      try {
        await this.getStatistics();
        await this.getMyTickets();
      } finally {
        this.loading = false;
      }
    },
    updateChartData() {
      this.chartDataAssigned = {
        labels: ["Asignados", "Vendidos", "Pagados"],
        datasets: [
          {
            label: "Boletos Asignados",
            data: [
              this.statistics.assigned,
              this.statistics.assigned_sold,
              this.statistics.assigned_paid,
            ],
            backgroundColor: ["#8083eb", "#83dae7", "#6ed4c3"],
            borderColor: ["#464dee", "#0ad7f7", "#0ddbb9"],
            borderWidth: 1,
          },
        ],
      };
      this.chartDataSold = {
        labels: ["Vendidos", "Pagados"],
        datasets: [
          {
            label: "Boletos Vendidos",
            data: [this.statistics.sold, this.statistics.paid],
            backgroundColor: ["#83dae7", "#6ed4c3"],
            borderColor: ["#0ad7f7", "#0ddbb9"],
            borderWidth: 1,
          },
        ],
      };
    },
    formatCurrency(value) {
      const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  watch: {
    statistics: {
      handler() {
        this.updateChartData();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
@import "@/assets/dashboard.css";
</style>
