ticketBook
<template>
  <div class="container px-4 py-5" id="featured-3">
    <h2 class="pb-2 border-bottom">Talonarios</h2>
    <div
      v-if="ticketBooks.length > 0"
      class="row justify-content-center row-cols-1 row-cols-md-3 mb-3 text-center d-flex align-items-stretch"
    >
      <div v-for="ticketBook in ticketBooks" :key="ticketBook.suid" class="col">
        <div class="card mb-4 h-100 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <div class="row">
              <div class="col-10">
                <h4 class="my-0 fw-normal">
                  {{ formattedDrawDate(ticketBook.draw_date) }}
                </h4>
              </div>
              <div
                :id="`buttonReferenceCopy-${ticketBook.suid}`"
                class="col-2"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Copiado!"
              >
                <div class="dropdown">
                  <button
                    class="btn btn-dropdown dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots-vertical"></i>
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <router-link
                        v-if="profile.suid === ticketBook.owner"
                        :to="`/advisor/management/${ticketBook.suid}`"
                        class="dropdown-item"
                      >
                        Asesores
                      </router-link>
                    </li>
                    <li>
                      <button
                        class="dropdown-item"
                        @click="copyLinkReference(ticketBook.suid)"
                        @mouseleave="hideTooltip($event)"
                      >
                        Referenciar
                      </button>
                    </li>
                    <li><a class="dropdown-item" href="#">Boletos</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <h3 class="fs-2 text-body-emphasis">{{ ticketBook.game_name }}</h3>
            <div>
              <p>{{ ticketBook.main_prize_description }}</p>
            </div>
            <h1 class="card-title pricing-card-title">
              {{ formatCurrency(ticketBook.ticket_value)
              }}<small class="text-body-secondary fw-light">/cop</small>
            </h1>
            <p>Propietario: {{ ticketBook.owner_name }}</p>
            <button
              type="button"
              class="w-100 btn btn-lg btn-secondary mb-3"
              @click="openModal(ticketBook.advanced_prizes)"
              data-bs-toggle="modal"
              data-bs-target="#advancedPrizesModal"
            >
              Ver Anticipados ({{ ticketBook.advanced_prizes.length }})
            </button>
            <div class="row">
              <div class="col-12 col-md-6">
                <router-link
                  ref="ticketBookLink"
                  :to="`/ticket/obtain/${ticketBook.suid}/${profile.suid}`"
                  class="w-100 btn btn-lg btn-primary"
                >
                  Ir
                </router-link>
              </div>
              <div class="col-12 col-md-6">
                <button
                  :id="`buttonCopyObtain-${ticketBook.suid}`"
                  type="button"
                  class="w-100 btn btn-lg btn-outline-primary"
                  @click="copyLinkObtain(ticketBook.suid)"
                  @mouseleave="hideTooltip($event)"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Copiado!"
                >
                  Copiar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else><p>No hay elementos para mostrar.</p></div>
    <AdvancedPrizesModal :currentAdvancedPrizes="currentAdvancedPrizes" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Tooltip } from "bootstrap";
import moment from "moment";
import AdvancedPrizesModal from "../components/tickets/retrieve/AdvancedPrizesModal.vue";

export default {
  name: "TicketBooks",
  components: {
    AdvancedPrizesModal,
  },
  data() {
    return {
      currentAdvancedPrizes: [],
    };
  },
  computed: {
    ...mapState(["ticketBooks", "profile"]),
  },
  mounted() {
    this.getTicketBooks();
    this.initializeTooltips();
  },
  methods: {
    ...mapActions(["getTicketBooks"]),
    formatCurrency(value) {
      const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formattedDrawDate(value) {
      return this.capitalize(moment(value).format("dddd D [de] MMMM YYYY"));
    },
    initializeTooltips() {
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl, {
          trigger: "manual",
        });
      });
    },
    showTooltip(event) {
      const tooltip = Tooltip.getInstance(event.currentTarget);
      if (tooltip) {
        tooltip.show();
      }
    },
    hideTooltip(event) {
      const tooltip = Tooltip.getInstance(event.currentTarget);
      if (tooltip) {
        tooltip.hide();
      }
    },
    copyLinkObtain(ticketBookSuid) {
      const path = "/ticket/obtain/";
      const elementId = "buttonCopyObtain";
      this.copyLink(ticketBookSuid, path, elementId);
    },
    copyLinkReference(ticketBookSuid) {
      const path = "/reference/advisor/ticket_book/";
      const elementId = "buttonReferenceCopy";
      this.copyLink(ticketBookSuid, path, elementId);
    },
    copyLink(ticketBookSuid, path, elementId) {
      // Construir la URL usando el objeto $route
      const url = `${window.location.origin}${path}${ticketBookSuid}/${this.profile.suid}`;

      // Crear un elemento temporal para copiar el texto
      const tempInput = document.createElement("input");
      tempInput.value = url;
      document.body.appendChild(tempInput);

      // Seleccionar y copiar el texto dentro del input temporal
      tempInput.select();
      document.execCommand("copy");

      // Remover el elemento temporal
      document.body.removeChild(tempInput);

      // Mostrar el tooltip manualmente después de copiar
      this.$nextTick(() => {
        const copyButton = document.getElementById(
          `${elementId}-${ticketBookSuid}`
        );
        const tooltip = Tooltip.getInstance(copyButton);
        if (tooltip) {
          tooltip.show();
        }
      });
    },
    openModal(advancedPrizes) {
      this.currentAdvancedPrizes = advancedPrizes;
    },
  },
};
</script>

<style scoped>
.card-header {
  background-color: #e4cb8b;
  color: #000000;
}
.btn-primary {
  background-color: #50bfe0;
  color: #ffffff;
}
.btn-no-hover:hover {
  background-color: #50bfe0;
  color: #fff;
  box-shadow: none;
  pointer-events: none;
}
.btn-light {
  border-color: #007bff;
}
.btn-outline-primary:hover {
  background-color: #e4cb8b;
  color: #000000;
}
.dropdown.btn.btn-dropdown {
  color: #e4cb8b;
}
</style>
