<template>
  <div
    class="modal fade"
    id="advancedPrizesModal"
    tabindex="-1"
    aria-labelledby="advancedPrizesModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="advancedPrizesModalLabel">
            Premios Anticipados
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="currentAdvancedPrizes.length">
            <div class="row justify-content-center text-center">
              <div
                class="col-12 col-md-6"
                v-for="prize in currentAdvancedPrizes"
                :key="prize.draw_date"
              >
                <div class="card mt-3">
                  <div class="card-body">
                    <h5 class="card-title mb-3">Descripción</h5>
                    <h4 class="card-title">{{ prize.prize_description }}</h4>
                    <div class="row mt-3">
                      <div class="col-md-6 col-12">
                        <p class="mb-1">Fecha del Sorteo:</p>
                        <p class="mt-1">
                          {{ formattedDrawDate(prize.draw_date) }}
                        </p>
                      </div>
                      <div class="col-md-6 col-12">
                        <p class="mb-1">Juega Con:</p>
                        <p class="mt-1">{{ prize.game_name }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AdvancedPrizesModal",
  props: {
    currentAdvancedPrizes: {
      type: Array,
      required: true,
    },
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formattedDrawDate(value) {
      return this.capitalize(moment(value).format("dddd D [de] MMMM YYYY"));
    },
  },
};
</script>

<style scoped>
.card {
  background-color: #e4cb8b;
  color: #000000;
}
</style>
