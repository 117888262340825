import AdvisorService from "@/services/AdvisorService";

const advisorAction = {
  async listAdvisors({ commit }, suid_ticket_book) {
    const response = await AdvisorService.listAdvisors(suid_ticket_book);
    commit("setAdvisors", response.data);
  },
  async filterAdvisors({ commit }, data) {
    const response = await AdvisorService.filterAdvisors(
      data.suid_ticket_book,
      data.phone_number
    );
    commit("setAdvisors", response.data);
  },
  async referenceAdvisor(context, data) {
    await AdvisorService.referenceAdvisor(data);
  },
  async assignTickets(context, data) {
    return await AdvisorService.assignTickets(data);
  },
  async getStatistics({ commit }) {
    const response = await AdvisorService.statistics();
    commit("setStatistics", response.data);
  },
  async getMyTickets({ commit }) {
    const response = await AdvisorService.myTickets();
    commit("setMyTickets", response.data);
  },
};

export default advisorAction;
