<template>
  <div class="container mt-5">
    <div class="row">
      <div v-if="ticketBook" class="col-12 col-md-4 mb-4 d-none d-md-block">
        <div class="card mt-3 align-items-center">
          <div class="card-body">
            <h5 class="card-title mb-3">Premio Principal</h5>
            <h4 class="card-text">
              {{ ticketBook.main_prize_description }}
            </h4>
            <div class="row mt-3">
              <div class="col-12 col-md-6">
                <p class="mb-1">Fecha del Sorteo:</p>
                <p class="mt-1">
                  {{ formattedDrawDate(ticketBook.draw_date) }}
                </p>
              </div>
              <div class="col-12 col-md-6">
                <p class="mb-1">Juega con:</p>
                <p class="mt-1">{{ ticketBook.game_name }}</p>
              </div>
            </div>
            <p class="card-text">
              Valor del Boleto: {{ formatCurrency(ticketBook.ticket_value) }}
            </p>
            <button
              class="btn btn-secondary"
              @click="openModal(ticketBook.advanced_prizes)"
              data-bs-toggle="modal"
              data-bs-target="#advancedPrizesModal"
            >
              Ver Premios Anticipados ({{ ticketBook.advanced_prizes.length }})
            </button>
            <div class="row mt-3">
              <div class="col-12 col-md-6">
                <p class="mb-1">Numero inicial:</p>
                <p class="mt-1">
                  {{ "0".repeat(ticketBook.digits_quantity) }}
                </p>
              </div>
              <div class="col-12 col-md-6">
                <p class="mb-1">Numero Final:</p>
                <p class="mt-1">
                  {{ "9".repeat(ticketBook.digits_quantity) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mx-auto mb-5">
        <div class="row">
          <div class="col-12 text-center mb-4">
            <img
              src="@/assets/logo.png"
              alt="Logo de la empresa"
              style="max-width: 200px; height: auto"
            />
          </div>
          <div class="col-12 mx-auto">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Buscar Boleto</h5>
                <form @submit.prevent="handledSearchNumberTicket">
                  <div class="mb-3" v-if="ticketBook">
                    <input
                      type="text"
                      class="form-control"
                      id="ticket_number"
                      v-model="ticket_number"
                      :placeholder="
                        'Número (' + ticketBook.digits_quantity + ' dígitos)'
                      "
                      :title="
                        'Ingrese exactamente ' +
                        ticketBook.digits_quantity +
                        ' dígitos'
                      "
                      :pattern="'[0-9]{' + ticketBook.digits_quantity + '}'"
                      :maxlength="ticketBook.digits_quantity"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                      inputmode="numeric"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-secondary btn-buscar"
                    :disabled="fetchingTickets"
                  >
                    Buscar
                  </button>
                  <span style="margin-left: 15px"></span>
                  <button
                    type="button"
                    @click="handledSearchRandomTicket()"
                    class="btn btn-light"
                    :disabled="fetchingTickets"
                  >
                    Aleatorio
                  </button>
                </form>
                <span
                  v-if="fetchingTickets"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mx-auto mb-5 d-none d-md-block">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="form-group">
                <label for="first_name">Nombre:</label>
                <input
                  v-model="customer_data.first_name"
                  type="text"
                  class="form-control"
                  id="first_name"
                  name="first_name"
                />
                <div v-if="errors.first_name">
                  <small
                    v-for="(error, index) in errors.first_name"
                    :key="index"
                    class="text-danger"
                    >{{ error }}<br
                  /></small>
                </div>
              </div>
              <div class="form-group">
                <label for="last_name">Apellido:</label>
                <input
                  v-model="customer_data.last_name"
                  type="text"
                  class="form-control"
                  id="last_name"
                  name="last_name"
                />
              </div>
              <div v-if="errors.last_name">
                <small
                  v-for="(error, index) in errors.last_name"
                  :key="index"
                  class="text-danger"
                  >{{ error }}<br
                /></small>
              </div>
              <div class="form-group">
                <label for="phone">Teléfono:</label>
                <input
                  v-model="customer_data.phone"
                  type="tel"
                  class="form-control"
                  id="phone"
                  name="phone"
                />
              </div>
              <div v-if="errors.phone">
                <small
                  v-for="(error, index) in errors.phone"
                  :key="index"
                  class="text-danger"
                  >{{ error }}<br
                /></small>
              </div>
              <div class="form-group">
                <label for="address">Dirección:</label>
                <input
                  v-model="customer_data.address"
                  type="text"
                  class="form-control"
                  id="address"
                  name="address"
                />
                <div v-if="errors.address">
                  <small
                    v-for="(error, index) in errors.address"
                    :key="index"
                    class="text-danger"
                    >{{ error }}<br
                  /></small>
                </div>
              </div>
              <div class="form-group">
                <label for="city">Ciudad:</label>
                <input
                  v-model="customer_data.city"
                  type="text"
                  class="form-control"
                  id="city"
                  name="city"
                />
                <div v-if="errors.city">
                  <small
                    v-for="(error, index) in errors.city"
                    :key="index"
                    class="text-danger"
                    >{{ error }}<br
                  /></small>
                </div>
              </div>
              <div class="form-group"></div>
              <div class="form-group form-check form-check-inline mb-4 mt-4">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="more_info"
                  name="more_info"
                  v-model="moreInfoChecked"
                />
                <label class="form-check-label" for="more_info"
                  >¿Más información?</label
                >
              </div>
              <div v-if="moreInfoChecked" class="form-group">
                <label for="whatsapp">Whatsapp:</label>
                <input
                  v-model="customer_data.whatsapp"
                  type="tel"
                  class="form-control"
                  id="whatsapp"
                  name="whatsapp"
                />
              </div>
              <div v-if="moreInfoChecked" class="form-group">
                <label for="email">Email:</label>
                <input
                  v-model="customer_data.email"
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mx-auto mb-5">
        <h5 class="text-center mb-4 text-effect">Números Solicitados</h5>
        <div v-if="searchedNumbers.length > 0">
          <div class="text-center mb-3">
            <button
              v-if="searchedNumbers && searchedNumbers.length > 0"
              class="btn btn-primary btn-comprar"
              @click="handledObtainTickets"
            >
              Comprar
            </button>
            <span style="margin-left: 15px"></span>
            <button class="btn btn-light" @click="handledClearSearchedNumbers">
              Limpiar
            </button>
          </div>
          <div v-if="searchedNumbers" class="row">
            <div
              v-for="item in searchedNumbers"
              :key="item.id"
              class="col-4 mx-auto mb-3"
            >
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{ item.number }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h4>Aún no has solicitado números</h4>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 d-block d-md-none">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="form-group">
                <label for="first_name">Nombre:</label>
                <input
                  v-model="customer_data.first_name"
                  type="text"
                  class="form-control"
                  id="first_name"
                  name="first_name"
                />
                <div v-if="errors.first_name">
                  <small
                    v-for="(error, index) in errors.first_name"
                    :key="index"
                    class="text-danger"
                    >{{ error }}<br
                  /></small>
                </div>
              </div>
              <div class="form-group">
                <label for="last_name">Apellido:</label>
                <input
                  v-model="customer_data.last_name"
                  type="text"
                  class="form-control"
                  id="last_name"
                  name="last_name"
                />
              </div>
              <div v-if="errors.last_name">
                <small
                  v-for="(error, index) in errors.last_name"
                  :key="index"
                  class="text-danger"
                  >{{ error }}<br
                /></small>
              </div>
              <div class="form-group">
                <label for="phone">Teléfono:</label>
                <input
                  v-model="customer_data.phone"
                  type="tel"
                  class="form-control"
                  id="phone"
                  name="phone"
                />
              </div>
              <div v-if="errors.phone">
                <small
                  v-for="(error, index) in errors.phone"
                  :key="index"
                  class="text-danger"
                  >{{ error }}<br
                /></small>
              </div>
              <div class="form-group">
                <label for="address">Dirección:</label>
                <input
                  v-model="customer_data.address"
                  type="text"
                  class="form-control"
                  id="address"
                  name="address"
                />
                <div v-if="errors.address">
                  <small
                    v-for="(error, index) in errors.address"
                    :key="index"
                    class="text-danger"
                    >{{ error }}<br
                  /></small>
                </div>
              </div>
              <div class="form-group">
                <label for="city">Ciudad:</label>
                <input
                  v-model="customer_data.city"
                  type="text"
                  class="form-control"
                  id="city"
                  name="city"
                />
                <div v-if="errors.city">
                  <small
                    v-for="(error, index) in errors.city"
                    :key="index"
                    class="text-danger"
                    >{{ error }}<br
                  /></small>
                </div>
              </div>
              <div
                class="form-group form-check form-check-inline mb-4 mt-4"
                ref="customerCard"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="more_info"
                  name="more_info"
                  v-model="moreInfoChecked"
                />
                <label class="form-check-label" for="more_info"
                  >¿Más información?</label
                >
              </div>
              <div v-if="moreInfoChecked" class="form-group">
                <label for="whatsapp">Whatsapp:</label>
                <input
                  v-model="customer_data.whatsapp"
                  type="tel"
                  class="form-control"
                  id="whatsapp"
                  name="whatsapp"
                />
              </div>
              <div v-if="moreInfoChecked" class="form-group">
                <label for="email">Email:</label>
                <input
                  v-model="customer_data.email"
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div v-if="ticketBook" class="col-12 col-md-4 mb-4 d-block d-md-none">
        <div class="card mt-3 align-items-center">
          <div class="card-body">
            <h5 class="card-title mb-3">Premio Principal</h5>
            <h4 class="card-text">
              {{ ticketBook.main_prize_description }}
            </h4>
            <div class="row mt-3">
              <div class="col-12 col-md-6">
                <p class="mb-1">Fecha del Sorteo:</p>
                <p class="mt-1">
                  {{ formattedDrawDate(ticketBook.draw_date) }}
                </p>
              </div>
              <div class="col-12 col-md-6">
                <p class="mb-1">Juega con:</p>
                <p class="mt-1">{{ ticketBook.game_name }}</p>
              </div>
            </div>
            <p class="card-text">
              Valor del Boleto: {{ formatCurrency(ticketBook.ticket_value) }}
            </p>
            <button
              class="btn btn-secondary"
              @click="openModal(ticketBook.advanced_prizes)"
              data-bs-toggle="modal"
              data-bs-target="#advancedPrizesModal"
            >
              Ver Premios Anticipados ({{ ticketBook.advanced_prizes.length }})
            </button>
            <div class="row mt-3">
              <div class="col-12 col-md-6">
                <p class="mb-1">Numero inicial:</p>
                <p class="mt-1">
                  {{ formattedDrawDate(ticketBook.draw_date) }}
                </p>
              </div>
              <div class="col-12 col-md-6">
                <p class="mb-1">Numero Final:</p>
                <p class="mt-1">{{ ticketBook.game_name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AdvancedPrizesModal :currentAdvancedPrizes="currentAdvancedPrizes" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import moment from "moment";
import AdvancedPrizesModal from "../components/tickets/retrieve/AdvancedPrizesModal.vue";

export default {
  name: "ObtainTicket",
  components: {
    AdvancedPrizesModal,
  },
  data() {
    return {
      suid_ticket_book: "",
      suid_advisor: "",
      ticket_number: "",
      fetchingTickets: false,
      currentAdvancedPrizes: [],
      moreInfoChecked: false,
      customer_data: {
        first_name: "",
        last_name: "",
        phone: "",
        address: "",
        city: "",
        whatsapp: "",
        email: "",
      },
      errors: {},
    };
  },
  computed: {
    ...mapState(["ticketBook", "searchedNumbers", "profile"]),
  },
  methods: {
    ...mapActions([
      "getTicketBookInfo",
      "searchNumberTicket",
      "searchRandomTicket",
      "clearSearchedNumbers",
      "obtainTickets",
    ]),
    formatCurrency(value) {
      const formatter = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formattedDrawDate(value) {
      return this.capitalize(moment(value).format("dddd D [de] MMMM YYYY"));
    },
    async handledSearchNumberTicket() {
      this.fetchingTickets = true;
      if (this.searchedNumbers.length >= 9) {
        Swal.fire({
          icon: "warning",
          title: "Limite maximo",
          text: "No puedes solicitar mas de 9 numeros.",
          confirmButtonText: "Entendido",
        });
      } else {
        const action = "handledSearchNumberTicket";
        const token = await this.$recaptcha(action);
        const data = {
          suid_ticket_book: this.suid_ticket_book,
          suid_advisor: this.suid_advisor,
          ticket_number: this.ticket_number,
          recaptcha: {
            token,
            action,
          },
        };
        try {
          await this.searchNumberTicket(data);
          this.ticket_number = "";
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error buscando el número!",
            footer: error.response.data.message,
          });
        }
      }
      this.fetchingTickets = false;
    },
    async handledSearchRandomTicket() {
      this.fetchingTickets = true;
      if (this.searchedNumbers.length >= 9) {
        Swal.fire({
          icon: "warning",
          title: "Limite maximo",
          text: "No puedes solicitar mas de 9 numeros.",
          confirmButtonText: "Entendido",
        });
      } else {
        const action = "handledSearchRandomTicket";
        const token = await this.$recaptcha(action);
        const data = {
          suid_ticket_book: this.suid_ticket_book,
          recaptcha: {
            token,
            action,
          },
        };
        try {
          await this.searchRandomTicket(data);
          this.ticket_number = "";
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error buscando el número!",
            footer: error.response.data.message,
          });
        }
      }
      this.fetchingTickets = false;
    },
    handledClearSearchedNumbers() {
      const data = {
        numbers: this.searchedNumbers,
        suid_ticket_book: this.suid_ticket_book,
        suid_advisor: this.suid_advisor,
      };
      this.clearSearchedNumbers(data);
    },
    openModal(advancedPrizes) {
      this.currentAdvancedPrizes = advancedPrizes;
    },
    handledObtainTickets() {
      if (!this.validateCustomerData()) {
        Swal.fire({
          icon: "warning",
          title: "Error de validación",
          text: "Por favor, diligencie todos los campos obligatorios.",
          confirmButtonText: "Entendido",
        }).then(() => {
          this.scrollToCard();
        });
      } else {
        try {
          const data = {
            suid_ticket_book: this.suid_ticket_book,
            suid_advisor: this.suid_advisor,
            customer_data: this.customer_data,
            searched_numbers: this.searchedNumbers,
          };
          this.obtainTickets(data);
          Swal.fire({
            icon: "success",
            title: "Exito!",
            text: "Se han obtenido los numeros correctamente.",
          }).then(() => {
            // Enviar mensaje a través de WhatsApp
            const numero = `+57${data.customer_data.phone}`;
            const numbersList = data.searched_numbers
              .map((n) => n.number)
              .join(", ");
            const mensaje = `Hola ${data.customer_data.first_name}, soy tu asesor(a) ${this.profile.natural_person.first_name}.
Tu compra de los números (${numbersList}) se registró correctamente. ¡Gracias por tu compra!
Puedes consultar el estado de tus boletos con tu número de teléfono en el siguiente enlace\n
https://violasuerte.cvalenciait.com/ticket/retrieve`;
            const url = `https://wa.me/${numero}?text=${encodeURIComponent(
              mensaje
            )}`;
            window.open(url, "_blank"); // Redirige a la URL de WhatsApp
          });
          this.cleanFormData();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error obteniendo los números!",
            footer: error.response.data.message,
          });
        }
      }
    },
    cleanFormData() {
      this.customer_data = {
        first_name: "",
        last_name: "",
        phone: "",
        address: "",
        city: "",
        whatsapp: "",
        email: "",
      };
    },
    validateCustomerData() {
      this.errors = {}; // Limpiar los errores existentes antes de validar nuevamente

      // Lógica de validación
      if (!this.customer_data.first_name) {
        this.errors.first_name = ["Este campo no puede estar en blanco."];
      }
      if (!this.customer_data.last_name) {
        this.errors.last_name = ["Este campo no puede estar en blanco."];
      }
      if (!this.customer_data.phone) {
        this.errors.phone = ["Este campo no puede estar en blanco."];
      }
      if (!this.customer_data.address) {
        this.errors.address = ["Este campo no puede estar en blanco."];
      }
      if (!this.customer_data.city) {
        this.errors.city = ["Este campo no puede estar en blanco."];
      }

      const requiredFields = [
        "first_name",
        "last_name",
        "phone",
        "address",
        "city",
      ];

      for (let field of requiredFields) {
        if (!this.customer_data[field]) {
          return false;
        }
      }

      return true;
    },
    scrollToCard() {
      this.$refs.customerCard.scrollIntoView({ behavior: "smooth" });
    },
  },
  async created() {
    const { suid_ticket_book, suid_advisor } = this.$route.params;
    this.suid_ticket_book = suid_ticket_book;
    this.suid_advisor = suid_advisor;
    try {
      await this.getTicketBookInfo(this.suid_ticket_book);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error obteniendo informacion del talonario!",
        footer: error.response.data.message,
      });
    }
  },
};
</script>

<style scoped>
body {
  background-color: #f8f9fa !important;
}
.btn-comprar {
  position: relative;
  overflow: hidden;
  background-color: #50bfe0; /* Color base del botón */
  color: #fff; /* Color del texto */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  animation: titilar 1.5s infinite;
}

@keyframes titilar {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.btn-comprar::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  transition: transform 0.5s ease-out;
  z-index: 0;
}

.btn-comprar:hover::before {
  transform: translate(-25%, -25%);
}

.btn-comprar span {
  position: relative;
  z-index: 1;
}
.btn-no-hover:hover {
  background-color: #50bfe0;
  color: #fff;
  box-shadow: none;
  pointer-events: none;
}
.btn-light {
  border-color: #007bff;
}
.btn-buscar {
  background-color: #e4cb8b;
  border-color: #007bff;
  border-width: 2px;
  color: #000000;

  /* Ajustes adicionales según necesidades */
  /* padding: 10px 20px; */
  /* font-size: 16px; */
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  /* transition: all 0.3s ease; */
}

.btn-buscar:hover {
  background-color: #c7b28f;
  border-color: #0056b3;
  color: #ffffff;
}

.btn-buscar:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

.btn-buscar:active {
  transform: translateY(1px);
}
.card {
  background-color: #e4cb8b;
  color: #000000;
}
.text-effect {
  color: #e4cb8b; /* Color del texto */
  padding: 10px;
  text-align: center; /* Alineación centrada del texto */
  margin-bottom: 20px; /* Espacio en la parte inferior */
  text-shadow: 2px 2px 0 #000,
    /* sombra negra a la derecha y abajo */ -1px -1px 0 #000,
    /* sombra negra a la izquierda y arriba */ -1px 1px 0 #000,
    /* sombra negra a la izquierda y abajo */ 1px -1px 0 #000,
    /* sombra negra a la derecha y arriba */ 1px 1px 5px #000; /* sombra negra más difusa */
  font-size: 34px; /* Tamaño de fuente más grande */
  font-family: "Arial Black", sans-serif; /* Fuente más gruesa */
}
</style>
