<template>
  <div class="container mt-5">
    <div
      v-if="registered || activated"
      class="alert alert-success mt-3 alert-dismissible"
      role="alert"
    >
      <p v-if="registered">
        Te has registrado exitosamente. Por favor, revisa tu correo electrónico
        para activar tu cuenta.
      </p>
      <p v-if="activated">Su cuenta ha sido activada con éxito!</p>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div
      v-if="registered"
      class="alert alert-warning mt-3 alert-dismissible"
      role="alert"
    >
      <p v-if="registered">
        Si no encuetras el correo electrónico te sugerimos revisar tu bandeja de
        spam o correo no deseado. A veces nuestros mensajes pueden terminar allí
        por error. ¡Gracias!
      </p>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div class="row">
      <div class="col-12 text-center mb-4">
        <img
          src="@/assets/logo.png"
          alt="Logo de la empresa"
          style="max-width: 200px; height: auto"
        />
      </div>
    </div>
    <h1>Iniciar Sesión</h1>
    <div class="row justify-content-center text-center">
      <div class="col-12 col-md-4 text-center">
        <form @submit.prevent="handledLogin">
          <div class="mb-3">
            <label for="username" class="form-label">Usuario</label>
            <input
              type="text"
              class="form-control"
              id="username"
              v-model="credentials.username"
              required
            />
            <div v-if="errors.username">
              <small
                v-for="(error, index) in errors.username"
                :key="index"
                class="text-danger"
                >{{ error }}<br
              /></small>
            </div>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Contraseña</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="credentials.password"
              required
            />
            <div v-if="errors.password">
              <small
                v-for="(error, index) in errors.password"
                :key="index"
                class="text-danger"
                >{{ error }}<br
              /></small>
            </div>
          </div>
          <button type="submit" class="btn btn-primary">Iniciar Sesión</button>
        </form>
      </div>
    </div>
    <p class="mt-3">
      ¿No tienes ninguna cuenta?
      <router-link to="/register">Regístrate</router-link>
    </p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "LoginForm",
  data() {
    return {
      credentials: {
        username: "",
        password: "",
      },
      errors: {},
      registered: false,
      activated: false,
    };
  },
  mounted() {
    if (this.$route.query.registered) {
      this.registered = true;
    }
    if (this.$route.query.activated) {
      this.activated = true;
    }
  },
  methods: {
    ...mapActions(["login"]),
    async handledLogin() {
      try {
        await this.login(this.credentials);
        this.$router.push("/");
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data;
          // Scroll hacia arriba para mostrar los errores debajo de los campos
          window.scrollTo(0, 0);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error de Inicio de Sesión",
            text: error.response.data.detail,
          });
        }
      }
    },
  },
};
</script>
